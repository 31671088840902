import { graphql } from 'gatsby'
import React from 'react'
import {Helmet} from 'react-helmet'
import {Container} from 'reactstrap'
import Moment from 'moment'
import Layout from '../../components/Layout/Layout'

import './NewsPage.scss'

export default class NewsPage extends React.Component {
    render () {
        let data = this.props.data.contentfulNews

        return <Layout pageContext={this.props.pageContext}>
            <Container className='content-page news-page'>
                <Helmet
                    title={data.seoTitle}
                    meta={[
                        { name: 'description', content: data.seoDescription.seoDescription },
                        { name: 'revised', content: data.createdAt },

                        { property: 'og:title', content: data.seoTitle },
                        { property: 'og:description', content: data.seoDescription.seoDescription },
                        {
                            property: 'og:image',
                            content: data.titleImage.gatsbyImageData.images.fallback.src
                        },
                        { property: 'og:image:width', content: '1200'},
                        { property: 'og:image:height', content: '630'},
                    ]}
                />

                <div className="pub-date">{Moment(data.publishedAt || data.createdAt).format('DD.MM.YYYY')}</div>
                <h1>{data.title}</h1>

                <div dangerouslySetInnerHTML={{__html: data.body.childMarkdownRemark.html}}/>
            </Container>
        </Layout>
    }
}


export const pageQuery = graphql`
    query NewsPageQuery($locale: String!, $slug: String!) {
        contentfulNews(node_locale: {eq: $locale}, slug: {eq: $slug}) {
            title
            createdAt
            publishedAt
            seoTitle
            seoDescription {
                seoDescription
            }
            titleImage {
                gatsbyImageData(layout: CONSTRAINED, width: 1200, height:630)
            }
            body {
                childMarkdownRemark {
                    html
                }
            }
        }
    }
`
